define("windapp/models/faq", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    question: _emberData.default.attr('string'),
    answer: _emberData.default.attr('string'),
    timestamp: _emberData.default.attr('number'),
    active: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr("date"),
    createdAtI: _emberData.default.attr("number"),
    // inverse
    updatedAt: _emberData.default.attr("date"),
    updatedAtI: _emberData.default.attr("number"),
    // inverse
    paginationLink: "faq.item"
  });

  _exports.default = _default;
});