define("windapp/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      signOut: function signOut() {
        this.get("session").close();
        return this.transitionTo('signin');
      }
    }
  });

  _exports.default = _default;
});