define("windapp/mixins/default-attrs", ["exports", "virtual-each/mixins/default-attrs"], function (_exports, _defaultAttrs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _defaultAttrs.default;
    }
  });
});