define("windapp/helpers/gett", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var gett = function gett(params) {
    return params[0][params[1]];
  };

  var _default = Ember.Helper.helper(gett);

  _exports.default = _default;
});