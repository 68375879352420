define("windapp/templates/components/paper-remove-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9fblETn6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-button\",null,[[\"iconButton\",\"onClick\"],[true,[27,\"action\",[[22,0,[]],\"areYouSure\"],null]]],{\"statements\":[[1,[27,\"paper-icon\",[\"close\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"warn\"]]],null,{\"statements\":[[0,\"    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"small\"],[11,\"style\",\"color: #aaa\"],[9],[0,\"bevestig\"],[10],[0,\" \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[4,\"paper-button\",null,[[\"iconButton\",\"warn\",\"onClick\"],[true,true,[27,\"action\",[[22,0,[]],[23,[\"onClick\"]]],null]]],{\"statements\":[[1,[27,\"paper-icon\",[\"check_circle\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "windapp/templates/components/paper-remove-button.hbs"
    }
  });

  _exports.default = _default;
});