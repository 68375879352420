// import Ember from 'ember';
//
// export function nltobr(params){
//   if(!params || !params[0])return "";
//   var nl2br = params[0].replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
//   return new Ember.Handlebars.SafeString(nl2br);
//     // return new Ember.Handlebars.SafeString(params[0].replace(/\n/g, "<br />"));
// }
//
//
// export default Ember.Helper.helper(nltobr);
define("windapp/helpers/nltobr", [], function () {
  "use strict";
});