define("windapp/controllers/news/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(Ember.Evented, {
    saveUpdate: "",
    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');
        model.setProperties({
          updatedAt: new Date(),
          updatedAtI: -new Date().getTime()
        });
        model.save().then(function () {
          _this.set('saveUpdate', 'Opgeslagen!');
        });
        this.trigger('saveContent');
      },
      cancel: function cancel() {
        this.transitionToRoute('news');
      },
      remove: function remove() {
        this.get('model').destroyRecord();
        this.send('cancel');
      },
      push: function push() {
        var model = this.get('model');
        var data = {
          "title": model.get('subject'),
          "action-type": "news",
          "action-key": model.get('id')
        };
        Ember.$.ajax("https://fcm.googleapis.com/fcm/send", {
          type: "POST",
          dataType: "json",
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', "key=AIzaSyCiGmMbdIp8Djw48XpuTfNpx6_2s5ft3FQ");
            xhr.setRequestHeader('Content-Type', "application/json");
          },
          data: JSON.stringify({
            data: data,
            to: "/topics/news",
            notification: {
              body: model.get('subject'),
              icon: 'ic_notification',
              click_action: 'OPEN_ACTIVITY_NEWS',
              color: '#388E3C' // title: model.get('subject')

            }
          })
        });
      }
    }
  });

  _exports.default = _default;
});