define("windapp/routes/faq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // beforeModel(){
    //     return this.get(this,'session').fetch().catch(function(){});
    // },
    model: function model() {
      return this.store.findAll("faq");
    }
  });

  _exports.default = _default;
});