define("windapp/helpers/is-undefined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.is_undefined = is_undefined;
  _exports.default = void 0;

  function is_undefined(params) {
    console.log(params);
    return params[0] === undefined;
  }

  var _default = Ember.Helper.helper(is_undefined);

  _exports.default = _default;
});