define("windapp/controllers/news/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    saveUpdate: "",
    actions: {
      save: function save() {
        var item = this.store.createRecord('newsitem', {
          subject: this.get('subject'),
          active: false,
          createdAt: new Date(),
          createdAtI: -new Date().getTime(),
          updatedAt: new Date(),
          updatedAtI: -new Date().getTime()
        });
        this.set('subject', '');
        this.set('message', '');
        this.set('saveUpdate', "Toegevoegd!");
        this.transitionToRoute('news.newsitem', item);
        item.save();
      },
      cancel: function cancel() {
        this.transitionToRoute('news');
      }
    }
  });

  _exports.default = _default;
});