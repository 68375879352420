define("windapp/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WJLnF2ga",
    "block": "{\"symbols\":[\"toggle\"],\"statements\":[[4,\"paper-toolbar\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"md-toolbar-tools\"],[9],[0,\"\\n\"],[4,\"paper-sidenav-toggle\",null,null,{\"statements\":[[2,\"            {{#paper-button onClick=(action \\\"toggle\\\" target=toggle) iconButton=true}}{{paper-icon icon=\\\"menu\\\"}}{{/paper-button}}\"],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[7,\"span\"],[11,\"class\",\"md-breadcrumb-page\"],[9],[0,\"Dashboard\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"paper-content\",null,[[\"class\"],[\"md-padding\"]],{\"statements\":[[4,\"paper-card\",null,null,{\"statements\":[[4,\"paper-card-content\",null,null,{\"statements\":[[0,\"            \"],[7,\"h2\"],[9],[0,\"Dashboard\"],[10],[0,\"\\n            \"],[7,\"p\"],[9],[0,\"Dit is het AdminPanel van de wind app.\"],[10],[0,\"\\n          \"],[7,\"br\"],[9],[10],[7,\"br\"],[9],[10],[0,\"\\n          \"],[7,\"p\"],[9],[0,\"\\n            Voor vragen neem contact op met \"],[7,\"a\"],[11,\"href\",\"https://www.livemegawatt.com\"],[11,\"target\",\"_blank\"],[9],[0,\"Live Megawatt\"],[10],[0,\":\"],[7,\"br\"],[9],[10],[7,\"br\"],[9],[10],[0,\"\\n            Jeppe Bijker\"],[7,\"br\"],[9],[10],[0,\"\\n            \"],[7,\"a\"],[11,\"href\",\"mailto:info@livemegawatt.com\"],[9],[0,\"info@livemegawatt.com\"],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "windapp/templates/index.hbs"
    }
  });

  _exports.default = _default;
});