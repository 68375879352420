define("windapp/router", ["exports", "windapp/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });
  Router.map(function () {
    this.route('faq');
    this.route('faq.add', {
      path: 'faq/add'
    });
    this.route('faq.item', {
      path: 'faq/item/:faq_id'
    });
    this.route('news');
    this.route('news.add', {
      path: 'news/add'
    });
    this.route('news.item', {
      path: 'news/item/:newsitem_id'
    });
    this.route('info');
    this.route('settings', function () {
      this.route('privacy', {
        path: 'settings/privacy'
      });
      this.route('disclaimer', {
        path: 'settings/disclaimer'
      });
      this.route('licenses', {
        path: 'settings/licenses'
      });
      this.route('projections', {
        path: 'settings/projections'
      });
      this.route('descriptions', {
        path: 'settings/descriptions'
      });
      this.route('turbinestatus', {
        path: 'settings/turbine-status'
      });
    });
    this.route('push');
    this.route('signin');
    this.route('contact', function () {
      this.route('message', {
        path: 'message/:newsitem_id'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});