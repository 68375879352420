define("windapp/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var _this = this;

      this.get('session').fetch().catch(function (error) {
        return _this.transitionTo('signin');
      });

      if (this.get("session") === undefined || this.get("session").isAuthenticated === undefined) {}
    },
    actions: {
      signOut: function signOut() {
        this.get("session").close();
        return this.transitionTo('signin');
      },
      transition: function transition() {
        this.transitionToRoute.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});