define("windapp/controllers/settings/projections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ref: Ember.inject.service('firebase'),
    "month-projections": [],
    "year-projection": null,
    "year-2016-projection": null,
    actions: {
      save: function save() {
        var ref = this.get('ref');
        ref.child('settings/month-projections').set(this.get('month-projections'));
        ref.child('settings/year-projection/value').set(this.get('year-projection'));
        ref.child('settings/year-2016-projection/value').set(this.get('year-2016-projection'));
      }
    },
    init: function init() {
      var _this = this;

      var ref = this.get('ref');
      ref.child('settings/month-projections').on("value", function (snapshot) {
        _this.set('month-projections', snapshot.val());
      });
      ref.child('settings/year-projection/value').on("value", function (snapshot) {
        _this.set('year-projection', snapshot.val());
      });
      ref.child('settings/year-2016-projection/value').on("value", function (snapshot) {
        _this.set('year-2016-projection', snapshot.val());
      });
    }
  });

  _exports.default = _default;
});