define("windapp/helpers/if_odd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.if_odd = if_odd;
  _exports.default = void 0;

  function if_odd(conditional, options) {
    console.log(conditional);
    console.log(options);
    return conditional % 2 === 1;
  }

  var _default = Ember.Helper.helper(if_odd);

  _exports.default = _default;
});