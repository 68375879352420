define("windapp/templates/contact/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AvApCjcX",
    "block": "{\"symbols\":[\"card\",\"title\",\"text\"],\"statements\":[[4,\"paper-card\",null,null,{\"statements\":[[4,\"component\",[[22,1,[\"content\"]]],null,{\"statements\":[[4,\"component\",[[22,1,[\"title\"]]],null,{\"statements\":[[4,\"component\",[[22,2,[\"text\"]]],null,{\"statements\":[[0,\"                \"],[4,\"component\",[[22,3,[\"headline\"]]],null,{\"statements\":[[0,\"Contact bericht\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null],[4,\"component\",[[22,1,[\"content\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"layout\",\"row\"],[11,\"class\",\"full-width-inset\"],[9],[0,\"\\n                \"],[1,[23,[\"model\",\"message\"]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[22,1,[\"actions\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"flex\",\"50\"],[9],[1,[23,[\"model\",\"mail\"]],false],[10],[0,\"\\n            \"],[4,\"paper-button\",null,[[\"raised\",\"primary\",\"onClick\"],[true,true,[27,\"action\",[[22,0,[]],\"mail\",[23,[\"model\",\"mail\"]]],null]]],{\"statements\":[[0,\"Antwoorden\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "windapp/templates/contact/message.hbs"
    }
  });

  _exports.default = _default;
});