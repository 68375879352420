define("windapp/controllers/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortedModel: Ember.computed.sort('model', 'sorting'),
    sorting: ['createdAtI'],
    columns: [{
      name: "mail",
      label: "E-mail adres",
      primary: true,
      style: Ember.String.htmlSafe('width: 40%')
    }, {
      name: "message",
      label: "Bericht",
      style: Ember.String.htmlSafe('width: 60%')
    }]
  });

  _exports.default = _default;
});