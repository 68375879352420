define("windapp/templates/settings/turbinestatus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "94HNlkhT",
    "block": "{\"symbols\":[\"card\",\"title\",\"text\"],\"statements\":[[4,\"paper-card\",null,null,{\"statements\":[[4,\"component\",[[22,1,[\"content\"]]],null,{\"statements\":[[4,\"component\",[[22,1,[\"title\"]]],null,{\"statements\":[[4,\"component\",[[22,2,[\"text\"]]],null,{\"statements\":[[0,\"                \"],[4,\"component\",[[22,3,[\"headline\"]]],null,{\"statements\":[[0,\"Uitleg turbine status\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null],[4,\"component\",[[22,1,[\"content\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"content-manager\",null,[[\"firebase-end-point\"],[\"info/turbine-status\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "windapp/templates/settings/turbinestatus.hbs"
    }
  });

  _exports.default = _default;
});