define("windapp/templates/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pohMa9Pp",
    "block": "{\"symbols\":[\"card\",\"toggle\"],\"statements\":[[4,\"paper-toolbar\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"md-toolbar-tools\"],[9],[0,\"\\n\"],[4,\"paper-sidenav-toggle\",null,null,{\"statements\":[[2,\"            {{#paper-button onClick=(action \\\"toggle\\\" target=toggle) iconButton=true}}{{paper-icon icon=\\\"menu\\\"}}{{/paper-button}}\"],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[7,\"span\"],[11,\"class\",\"md-breadcrumb-page\"],[9],[0,\"Contact\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"paper-content\",null,[[\"class\"],[\"md-padding\"]],{\"statements\":[[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n\\n\"],[4,\"paper-card\",null,null,{\"statements\":[[4,\"component\",[[22,1,[\"content\"]]],null,{\"statements\":[[4,\"component\",[[22,1,[\"content\"]]],null,{\"statements\":[[0,\"                \"],[1,[27,\"fb-pagination\",null,[[\"columns\",\"content\",\"pageLimit\"],[[23,[\"columns\"]],[23,[\"sortedModel\"]],5]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "windapp/templates/contact.hbs"
    }
  });

  _exports.default = _default;
});