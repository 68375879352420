define("windapp/templates/components/optional-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wxzd1Xyj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"loading\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"paper-progress-linear\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"style\",\"visibility: hidden\"],[9],[0,\"\\n        \"],[1,[21,\"paper-progress-linear\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "windapp/templates/components/optional-loader.hbs"
    }
  });

  _exports.default = _default;
});