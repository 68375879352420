define("windapp/controllers/faq/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    saveUpdate: "",
    actions: {
      save: function save() {
        var item = this.store.createRecord('faq', {
          question: this.get('question'),
          active: false,
          createdAt: new Date(),
          createdAtI: -new Date().getTime(),
          updatedAt: new Date(),
          updatedAtI: -new Date().getTime()
        });
        this.set('question', '');
        this.set('saveUpdate', "Toegevoegd!");
        this.transitionToRoute('faq.item', item);
        item.save();
      },
      cancel: function cancel() {
        this.transitionToRoute('faq');
      }
    },
    activeControllerListener: function () {
      console.log(this.get('controllers.faq'));
    }.on('init')
  });

  _exports.default = _default;
});