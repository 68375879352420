define("windapp/components/feedback-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ref: Ember.inject.service('firebase'),
    model: null,
    "firebase-url": null,
    hasFeedback: false,
    yesCount: 0,
    yesPerc: 0,
    noCount: 0,
    noPerc: 0,
    hasMessages: false,
    messages: [{
      icon: "thumb down",
      text: "hoi"
    }, {
      icon: "thumb up",
      text: "hoi2"
    }],
    init: function init() {
      this._super();

      var _this = this;

      var ref = this.get('ref');
      ref.child(this.get('firebase-url')).orderByChild('createdAtI').on("value", function (snapshot) {
        var messages = [];
        var yesCount = 0;
        var noCount = 0;
        snapshot.forEach(function (feedback) {
          var opinion = feedback.child('opinion').val();
          var message = feedback.child('message').val();

          if (opinion) {
            yesCount++;
          } else {
            noCount++;
          }

          if (message !== "" && message != null) {
            messages.push({
              icon: opinion ? "thumb-up" : "thumb-down",
              text: message
            });
          }
        });

        _this.set('yesCount', yesCount);

        _this.set('yesPerc', Ember.String.htmlSafe(Math.round(yesCount / (yesCount + noCount) * 100)));

        _this.set('noCount', noCount);

        _this.set('noPerc', Math.round(noCount / (yesCount + noCount) * 100));

        _this.set('messages', messages);

        _this.set('hasFeedback', yesCount + noCount > 0);

        _this.set('hasMessages', messages.length > 0);
      });
    }
  });

  _exports.default = _default;
});