define("windapp/torii-adapters/application", ["exports", "emberfire/torii-adapters/firebase"], function (_exports, _firebase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _firebase.default.extend({
    firebase: Ember.inject.service()
  });

  _exports.default = _default;
});