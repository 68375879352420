define("windapp/components/content-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    content: [],
    "firebase-end-point": "/",
    "show-save-button": true,
    "image-uploads": [],
    ref: Ember.inject.service('firebase'),
    actions: {
      removeContent: function removeContent(item) {
        this.get('content').removeObject(item);
      },
      addContent: function addContent(index) {
        if (index === "end") {
          console.log("add object");
          this.get('content').pushObject({
            type: 'new'
          });
        } else {
          console.log("add splice object");
          this.get('content').insertAt(index, {
            type: 'new'
          });
        }
      },
      setNewContentType: function setNewContentType(item, type) {
        Ember.set(item, 'type', type);
      },
      saveContent: function saveContent() {
        var uploadPromises = [];

        if (this.get('image-uploads') != null) {
          this.get('image-uploads').forEach(function (imageUpload) {
            uploadPromises.push(imageUpload.upload());
          });
        }

        var _this = this;

        var ref = _this.get('ref');

        Ember.RSVP.Promise.all(uploadPromises).then(function (value) {
          ref.child(_this.get('firebase-end-point')).set(_this.get('content')); // eerste uploaden van ima
        });
      },
      moveContent: function moveContent(direction, index) {
        var switchIndex = index + (direction === 'up' ? -1 : 1);
        console.log("move (" + index + ", " + switchIndex + ")");
        var content = this.get('content');
        var tmp = content[switchIndex];
        this.get('content').replace(switchIndex, 1, content[index]);
        this.get('content').replace(index, 1, tmp);
      }
    },
    linkValidation: [{
      'message': 'Link is niet goed ingevuld. De link moet beginnen met http://',
      validate: function validate(value) {
        return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(value);
        ;
      }
    }],
    // testObserver: function(){
    //     console.log("here");
    // }.observes('content.[]'),
    init: function init() {
      console.log('init content-manager');

      this._super();

      var _this = this;

      var ref = this.get('ref');
      ref.child(this.get("firebase-end-point")).on("value", function (snapshot) {
        _this.set('content', []);

        if (snapshot.val() != null) {
          snapshot.val().forEach(function (item) {
            _this.get('content').pushObject(item);
          });
        }
      });
    }
  });

  _exports.default = _default;
});