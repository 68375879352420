define("windapp/components/fb-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ref: Ember.inject.service('firebase'),
    page: 1,
    pageLimit: 5,
    firstPage: true,
    notFirstPage: false,
    lastPage: false,
    notLastPage: true,
    singlePage: false,
    multiPage: true,
    search: "",
    didChange: false,
    pagedContent: Ember.computed('page', 'pageLimit', 'searchedContent', function () {
      var page = this.get('page');
      var pageLimit = this.get('pageLimit');
      var searchedContent = this.get('searchedContent');
      var pagedContent = searchedContent.slice((page - 1) * pageLimit, page * pageLimit);
      return pagedContent;
    }),
    indexedContent: Ember.computed('columns', 'didChange', function () {
      this.set('didChange', false);
      console.log('update index');
      var content = this.get('content');
      var indexedContent = [];
      var columns = this.get('columns');

      if (content !== undefined) {
        content.forEach(function (content) {
          var searchString = "";

          for (var i = 0; i < columns.length; i++) {
            searchString += content.get(columns[i].name);
          }

          indexedContent.pushObject(searchString);
        });
      }

      return indexedContent;
    }),
    searchedContent: Ember.computed('content', 'indexedContent', 'search', function () {
      console.log("update searched");
      var content = this.get('content');
      var indexedContent = this.get('indexedContent');
      var searchedContent = [];
      var search = this.get('search');

      if (search === "") {
        return content;
      }

      $(indexedContent).each(function (i, indexedContent) {
        if (indexedContent.toLowerCase().includes(search.toLowerCase())) {
          searchedContent.pushObject(content.objectAt(i));
        }
      });
      return searchedContent;
    }),
    entries: Ember.computed("searchedContent", function () {
      return this.get('searchedContent').get('length');
    }),
    pages: Ember.computed('entries', 'pageLimit', function () {
      var pages = Math.ceil(this.get('entries') / this.get('pageLimit'));
      this.set('singlePage', pages === 1);
      this.set('multiPage', pages !== 1);
      return pages;
    }),
    actions: {
      prev: function prev() {
        this.moveTo(-1);
      },
      next: function next() {
        this.moveTo(1);
      }
    },
    moveTo: function moveTo(shift) {
      var page = this.get('page');
      var pages = this.get('pages');
      var pageLimit = this.get("pageLimit");
      var content = this.get("content");
      var needsRefresh = false;

      if (shift === -1 && page > 1) {
        page--;
        needsRefresh = true;
      } else if (shift === 1 && page < pages) {
        page++;
        needsRefresh = true;
      }

      if (needsRefresh) {
        this.set('page', page);
      }

      this.updateButtons();
    },
    updateButtons: function () {
      var page = this.get('page');
      var pages = this.get('pages');

      if (page === 1) {
        this.set('firstPage', true);
        this.set('notFirstPage', false);
      } else {
        this.set('firstPage', false);
        this.set('notFirstPage', true);
      }

      if (page === pages) {
        this.set('lastPage', true);
        this.set('notLastPage', false);
      } else {
        this.set('lastPage', false);
        this.set('notLastPage', true);
      }

      if (page !== 1 && page !== pages) {
        this.set('firstPage', false);
        this.set('notFirstPage', true);
        this.set('lastPage', false);
        this.set('notLastPage', true);
      }
    }.observes("search", "pages"),
    init: function init() {
      this._super();

      var _this = this;

      var columns = this.get('columns');

      for (var i = 0; i < columns.length; i++) {
        console.log('content.@each.' + columns[i].name);
        this.addObserver('content.@each.' + columns[i].name, this, "reIndex");
      }

      this.moveTo(0);
    },
    reIndex: function reIndex() {
      console.log("reindex");
      this.set('didChange', true);
    }
  });

  _exports.default = _default;
});