define("windapp/models/newsitem", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    subject: _emberData.default.attr('string'),
    message: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr("date"),
    createdAtI: _emberData.default.attr("number"),
    // inverse
    updatedAt: _emberData.default.attr("date"),
    updatedAtI: _emberData.default.attr("number"),
    // inverse
    messageShort: Ember.computed('message', function () {
      var message = "".concat(this.get('message'));

      if (message.length > 100) {
        message = message.substring(0, 100) + "...";
      }

      return message;
    }),
    paginationLink: "news.item"
  });

  _exports.default = _default;
});