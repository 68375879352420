define("windapp/controllers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ref: Ember.inject.service('firebase'),
    pageTitle: "Dashboard",
    windspeed: null,
    actions: {
      setWindSpeed: function setWindSpeed(windspeed) {
        var ref = this.get('ref');
        ref.child('/tmp/windspeed').set(windspeed);
      },
      signOut: function signOut() {
        this.get("session").close();
        return this.transitionTo('signin');
      }
    },
    init: function init() {
      var _this = this;

      var ref = this.get('ref'); // ref.child('/tmp/windspeed').on("value", function (snapshot) {
      //   _this.setProperties({windspeed: snapshot.val()});
      // });
    }
  });

  _exports.default = _default;
});