define("windapp/controllers/faq/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    saveUpdate: "",
    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');
        model.setProperties({
          updatedAt: new Date(),
          updatedAtI: -new Date().getTime()
        });
        model.save().then(function () {
          _this.set('saveUpdate', 'Opgeslagen!');
        });
        this.trigger('saveContent');
      },
      cancel: function cancel() {
        this.transitionToRoute('faq');
      },
      remove: function remove() {
        this.get('model').destroyRecord();
        this.send('cancel');
      }
    }
  });

  _exports.default = _default;
});