define("windapp/controllers/signin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    email: null,
    password: null,
    isLoading: false,
    error: "",
    // beforeModel: function() {
    //     if (this.get("session") !== undefined && this.get("session").isAuthenticated) {
    //         console.log(this.get("session"));
    //         console.log("signed-in");
    //         return this.transitionTo('index');
    //     }
    // },
    actions: {
      signIn: function signIn() {
        var _this = this;

        this.set('isLoading', true);
        var controller = this;
        this.get('session').open('firebase', {
          provider: 'password',
          email: this.get('email') || '',
          password: this.get('password') || ''
        }).then(function () {
          controller.set('email', null);
          controller.set('password', null);

          _this.set('isLoading', false);

          controller.transitionToRoute('index');
        }, function (error) {
          _this.set('isLoading', false);

          _this.set('error', error);
        });
      }
    }
  });

  _exports.default = _default;
});