define("windapp/controllers/faq", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/controllers/posts.js
  var _default = Ember.Controller.extend({
    sortedModel: Ember.computed.sort('model', 'sorting'),
    sorting: ['updatedAtI'],
    "show-pagination": true,
    columns: [{
      name: "question",
      label: "Vraag",
      primary: true
    }]
  });

  _exports.default = _default;
});