define("windapp/helpers/get_month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.get_month = get_month;
  _exports.default = void 0;

  function get_month(params) {
    var months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
    return months[params[0]];
  }

  var _default = Ember.Helper.helper(get_month);

  _exports.default = _default;
});