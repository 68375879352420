define("windapp/controllers/push", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ref: Ember.inject.service('firebase'),
    title: null,
    message: null,
    image: null,
    upload: [],
    actionValue: null,
    senderId: 429781468292,
    newsitems: [],
    actions: {
      send: function send() {
        if (this.get('upload').length > 0) {
          var uploadPromises = [];
          uploadPromises.push(this.get('upload')[0].upload());

          var _this = this;

          Ember.RSVP.Promise.all(uploadPromises).then(function (value) {
            _this.sendNotification(value[0]["file-name"]);
          });
        } else {
          this.sendNotification();
        }
      }
    },
    sendNotification: function sendNotification(image) {
      var _this = this;

      var data = {
        "title": _this.get('title'),
        "message": _this.get('message')
      };

      if (image != null) {
        data.image = image;
      }

      if (this.get('actionValue') != null) {
        data["action-type"] = this.get('actionValue').type;
        data["action-key"] = this.get('actionValue').key;
      }

      Ember.$.ajax("https://fcm.googleapis.com/fcm/send", {
        type: "POST",
        dataType: "json",
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', "key=AIzaSyCiGmMbdIp8Djw48XpuTfNpx6_2s5ft3FQ");
          xhr.setRequestHeader('Content-Type', "application/json");
        },
        data: JSON.stringify({
          data: data,
          to: "/topics/news",
          notification: {
            body: _this.get('title'),
            icon: 'ic_notification',
            color: '#388E3C' // title : _this.get('title')

          }
        })
      });
    },
    newsItemsListener: function () {
      var _this = this;

      var ref = this.get('ref');
      ref.child('/newsitems').on("child_added", function (snapshot) {
        _this.get('newsitems').pushObject({
          key: {
            key: snapshot.key,
            value: snapshot.child('subject').val(),
            type: "news"
          },
          value: snapshot.child('subject').val()
        });
      });
    }.on('init')
  });

  _exports.default = _default;
});