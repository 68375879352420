define("windapp/controllers/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ref: Ember.inject.service('firebase'),
    infotextwall: null,
    infowallimage: null,
    infowallimagewidth: null,
    infowallimageheight: null,
    infotext: null,
    info: null,
    "image-uploads": [],
    wallimageloaded: false,
    showField: "wall",
    actions: {
      saveText: function saveText() {
        var uploadPromises = [];
        this.get('image-uploads').forEach(function (imageUpload) {
          uploadPromises.push(imageUpload.upload());
        });

        var _this = this;

        var ref = _this.get('ref');

        Ember.RSVP.Promise.all(uploadPromises).then(function (value) {
          console.log(value);
          ref.child('info/wall/about/text').set(_this.get('infotextwall'));
          ref.child('info/wall/about/image-url').set(_this.get('infowallimage'));
          ref.child('info/wall/about/image-width').set(_this.get('infowallimagewidth'));
          ref.child('info/wall/about/image-height').set(_this.get('infowallimageheight'));
          console.log('width: ' + _this.get('infowallimagewidth'));
        });
      },
      setField: function setField(field) {
        this.set("showField", field);
      }
    },
    init: function init() {
      var _this = this;

      var ref = this.get('ref');
      ref.child('info/wall/about').on("value", function (snapshot) {
        _this.setProperties({
          infotextwall: snapshot.child('text').val()
        });

        _this.setProperties({
          infowallimage: snapshot.child('image-url').val()
        });

        _this.setProperties({
          infowallimagewidth: snapshot.child('image-width').val()
        });

        _this.setProperties({
          infowallimageheight: snapshot.child('image-height').val()
        });
      });
    }
  });

  _exports.default = _default;
});