define("windapp/helpers/eq_minus_one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eq_minus_one = eq_minus_one;
  _exports.default = void 0;

  function eq_minus_one(params) {
    return params[0] === params[1] - 1;
  }

  var _default = Ember.Helper.helper(eq_minus_one);

  _exports.default = _default;
});