define("windapp/components/image-upload", ["exports", "ember-uploader/components/file-field", "ember-uploader/uploaders/uploader"], function (_exports, _fileField, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(Ember.Evented, {
    file: null,
    "upload-file": null,
    "image-url": null,
    "image-width": null,
    "image-height": null,
    observer1: function () {
      // keep, allows for (eq image-url undefined) in .hbs
      console.log(this.get('image-url'));
    }.observes('image-url').on('init'),
    "new-image": null,
    isLoading: false,
    actions: {
      fileSelected: function fileSelected(element) {
        console.log('set upload-file: ' + element.target.files);
        this.set('upload-file', element.target.files);

        if (element.target.files && element.target.files[0]) {
          var reader = new FileReader();

          var _this = this;

          reader.onload = function (e) {
            _this.set('new-image', e.target.result);
          };

          console.log('FB: ' + _this.get('firebase-end-point'));
          reader.readAsDataURL(element.target.files[0]);

          if (this.get('image-upload-array') !== undefined) {
            this.get('image-upload-array').pushObject(this);
          }
        }
      },
      openFileDialog: function openFileDialog(className) {
        console.log(className);
        Ember.$("input[type='file']." + className).click();
      }
    },
    upload: function upload() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        console.log('image-upload upload');

        var uploader = _uploader.default.create({
          url: "https://www.ventolineswindapp.nl/westermeerwind/file-upload.php"
        });

        if (!Ember.isEmpty(_this.get('upload-file'))) {
          if (!_this.isDestroyed) {
            _this.set('isLoading', true);
          }

          var files = _this.get('upload-file');

          uploader.upload(files[0]).then(function (value) {
            console.log('image-upload uploaded: ' + (_this.isDestroyed ? 'destroyed' : 'not-destroyed'));
            console.log(value);

            if (!_this.isDestroyed) {
              _this.set('new-image', null);

              _this.set('isLoading', false);

              _this.set('image-width', value['width']);

              _this.set('image-height', value['height']);

              _this.set('image-url', value['file-name']);

              _this.set('upload-file', null);
            }

            console.log("image-upload done, width: " + value['width']);
            resolve(value);
          });
        }
      });
    },
    init: function init() {
      this._super();
    }
  });

  _exports.default = _default;
});