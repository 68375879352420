define("windapp/models/form", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    mail: _emberData.default.attr('string'),
    message: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr("date"),
    createdAtI: _emberData.default.attr("number"),
    // inverse
    paginationLink: "contact.message"
  });

  _exports.default = _default;
});