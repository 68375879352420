define("windapp/helpers/mail_to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mail_to = mail_to;
  _exports.default = void 0;

  function mail_to(params) {
    console.log("mail to");
    console.log(params);
  }

  var _default = Ember.Helper.helper(mail_to);

  _exports.default = _default;
});