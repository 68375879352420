define("windapp/components/paper-remove-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    warn: false,
    actions: {
      areYouSure: function areYouSure() {
        this.set('warn', !this.get('warn'));
      }
    }
  });

  _exports.default = _default;
});